import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import Icon from "../images/facebook-logo.svg";

const AboutPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
						company
					}
				}
			}
		`
	);

	const companyName = data.site.siteMetadata.company;
	const title = `Thank you for contacting ${companyName}`;

	return (
		<Layout hideNavbar>
			<GatsbySeo title={title} language="en" noindex />
			<section>
				<Container>
					<Row className="py-5">
						<Col className="text-center">
							<h1 className="pb-3 text-primary">THANK YOU!</h1>
							<p className="text-secondary mb-3">
								{" "}
								We’ll reach out to you soon!
							</p>
							<p className="text-secondary mb-3">
								{" "}
								Feel free to call us on{" "}
								<a href="tel:07771 703247" className="pink-link">
									07771 703247
								</a>{" "}
								or email{" "}
								<a
									href="mailto:sales@hartlandhoodies.com"
									className="pink-link"
								>
									sales@hartlandhoodies.com
								</a>
							</p>
							<p className="text-secondary mb-3">or visit our FB Page</p>
							<a href="https://www.facebook.com/245545532982792">
								<img
									src={Icon}
									className=""
									alt="Facebook Logo"
									style={{
										width: "60px",
									}}
								/>
							</a>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};
export default AboutPage;
